import React, { useState, useEffect } from "react";
import "./ServiceDetails.css";
import logoFont from "../../assets/font/InriaSans-Bold.ttf";
import { useNavigate, useLocation } from "react-router-dom";

import { BiArrowBack } from "react-icons/bi";

const ServiceDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);
  const [navbar, setNavbar] = useState(false);
  const [click, setClick] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const gradient = "linear-gradient(#360033 -28.1%, #0BB798 159.99%)";
  const serviceDetailJSON = new URLSearchParams(location.search).get(
    "service-detail"
  );

  // Parse the JSON string back to an object
  const serviceDetail = serviceDetailJSON
    ? JSON.parse(decodeURIComponent(serviceDetailJSON))
    : null;

  console.log(serviceDetail);

  return (
    <div>
      <nav
        className="w-screen    z-10  h-[80px]   top-0 fixed"
       
      >
        <div className="  mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center px-4  justify-between py-3 md:py-5 md:block mobile-nav-heading">
              <BiArrowBack
              style={{ cursor: 'pointer'}}
                color="#FFF"
                onClick={() => navigate(-1)}
                size={28}
              />
              <h1
                style={{ fontFamily: logoFont }}
                className="text-2xl font-bold lg:mr-30 md:mr-10 sm:mr-0 sm:text sm:ml-3 text-white"
              >
                {serviceDetail?.title}
              </h1>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3  md:block md:pb-0 md:mt-0 ${
                navbar ? "block mobile_nav mt-3 px-0 pb-0 " : "hidden"
              }`}
            >
              <ul
                className={
                  click
                    ? "items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 active"
                    : "items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0"
                }
              ></ul>
            </div>
          </div>
        </div>
      </nav>

      <div style={{ background: gradient, paddingTop: "5rem" }}>
        <div className="service-details-container">
          {/* Render the service details */}
          <p className="service-description">{serviceDetail?.description}</p>

          {/* Render the long description */}
          <div className="long-description">
            <p className="introduction">
              {serviceDetail?.long_description?.introduction}
            </p>
            <p className="approach">
              {serviceDetail?.long_description?.approach}
            </p>
          </div>

          {/* Render services */}
          <div className="services">
            {serviceDetail?.long_description?.services.map((service, index) => (
              <div key={index}>
                <h3>{Object.keys(service)[0]} :</h3>
                <p>{Object.values(service)[0]}</p>
              </div>
            ))}
          </div>

          {/* Render process */}
          <div className="process">
            {serviceDetail?.long_description?.process.map((step, index) => (
              <div key={index}>
                <h3>{Object.keys(step)[0]} :</h3>
                <p>{Object.values(step)[0]}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
