import { Route, BrowserRouter as Router, Routes } from "react-router-dom"; 
import TechStack from "./components/TechStack/TechStack"; 

import ServiceDetails from "./components/ServiceDetials/ServiceDetails";
import Home from "./components/Home/Home";

function App() {
  const gradient = "linear-gradient(#360033 -28.1%, #0BB798 159.99%)";

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/tech-stack" element={<TechStack />} />
      </Routes>
    </Router>
  );
}

export default App;
