import React from "react";
import "./Expertise.css";
import useMediaQuery from "use-mediaquery";

const Expertise = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div id="expertise" className="main-expertise-container">
      <h1
        style={{
          textAlign: "center",
          color: "#000",
          fontFamily: "Piazzolla",
          margin: "20px 0px",
          fontSize: isMobile ? "32px" : "",
        }}
      >
        Experties
      </h1>
      <h4 className="main-heading-2">
        We offer a wide range of Experties & Provide <br /> complete client
        satisfaction
      </h4>
      <div className="content-expertise">
        <div className="content-boxes">
          <h4>Fintech</h4>
        </div>
        <div className="content-boxes">
          <h4>Travel </h4>
        </div>
        <div className="content-boxes">
          <h4>E-learning</h4>
        </div>
        <div className="content-boxes">
          <h4>Health Care</h4>
        </div>
        <div className="content-boxes">
          <h4>Ecommerce</h4>
        </div>
        <div className="content-boxes">
          <h4>Education</h4>
        </div>
      </div>
      <h3
        style={{
          marginTop: "130px",
          fontFamily: "Poppins",
          fontWeight: "400",
          lineHeight: isMobile ? "32px" : "60px",
          fontSize: isMobile ? "18px" : "32px",
          textTransform: "capitalize",
          color: '#000',
          textAlign: "center",
          marginTop: "60px",
        }}
      >
        {isMobile ? (
          <>
            An agency committed to evolve abstract ideas into concreate digital
            products.
          </>
        ) : (
          <>
            An agency committed to evolve abstract ideas into <br /> concreate
            digital products.
          </>
        )}
      </h3>
    </div>
  );
};

export default Expertise;
