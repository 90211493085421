import React, { useEffect, useState, useRef } from "react";
import BannerImage from "../../assets/images/banner.png";
import illusionBall from "../../assets/images/Ellipse 1.svg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-scroll";
import "./Banner.css";
import useMediaQuery from "use-mediaquery";

const Banner = () => {
 

  const isMobile = useMediaQuery("(max-width: 768px)");

 /*  useEffect(() => {
    const text = textRef.current;
    text.innerHTML = text.innerText
      .split("")
      .map(
        (char, i) =>
          `<span style="transform:rotate(${i * 5.3}deg)">${char}</span>`
      )
      .join("");
  }, []); */

  return (
    <div className="flex justify-center  pt-28" id="home">
      <div className=" ">
        <div className="flex main-wrapper">
          <span className="main_text text-bold lg:text-7xl md:text-3xl  ">
            <p
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "#000"
              }}
            >
              Building <br /> Digital Brands
            </p>
            <span className="lg:ml-80">& Products</span>
          </span>

          <div className="mt-10 px-8 right_text">
            <p>
              {isMobile ? (
                <p className="right-text-p">
                  We work with brands, businesses and individuals who are
                  committed to moving things forward through vision, action and
                  leadership.
                </p>
              ) : (
                <>
                  We work with brands, businesses and individuals who are <br />
                  committed <br /> to moving things forward through vision,
                  action and <br /> leadership.
                </>
              )}
            </p>
          </div>
        </div>

        <div className="banner_image_wrapper">
          <img src={BannerImage} alt="" className="banner_image" />
         {/*  {isMobile ? (
            <></>
          ) : (
            <>
              <div className="banner_image_bottom_ball">
                <img src={illusionBall} alt="" />
              </div>
            </>
          )} */}

          {/* <div className="banner_circle ">
            <img src={illusionBall} alt="" />
            <div ref={textRef} className="banner_circleText">
              <p>Lets&nbsp;Start&nbsp;your&nbsp;Project</p>
            </div>
          </div> */}
        </div>

        <div className="banner_text_container">
          <p style={{ fontSize: '20px'}}>
            We have a clear strategy that helps to <br /> bring each project to
            it’s goal
          </p>
          <Link
            to="contactUs"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="banner_text_button"
          >
            Start Project
            <AiOutlineArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
