import React from "react";
import "./Contact.css";
import { FaInstagram, FaMap } from "react-icons/fa";

import useMediaQuery from "use-mediaquery";

const Contact = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleContactUsClick = () => {
    const emailAddress = "connect@brotocode.com"; // Replace with your email
    const subject = "Client Details"; // You can customize the email subject here

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  };
  return (
    <>
      <div id="contactUs" className="main-contact-us">
        <h1
          style={{
            textAlign: "center",
            color: "#000",
            fontFamily: "Piazzolla",
            margin: "20px 0px",
            fontSize: isMobile ? "32px" : "",
            marginTop: isMobile ? "4rem" : "",
          }}
        >
          Contact Us
        </h1>
        <div className="contact-box">
          <p>TELL US ABOUT YOUR PROJECT</p>
          {isMobile ? <></> : <h1>Have a project in mind?</h1>}

          <button onClick={handleContactUsClick}>Send Email</button>
        </div>
      </div>

      <div
        style={{
          background: "#f6edfa", 
        }}
      >
        <div className="address">
          <div className="left-address">
            <h2>Contact</h2>
            <h6>BroToCode</h6>
            <span>
              <FaMap /> <p>Telangana, Hyderabad, 502001</p>
            </span>
          </div>

          <div className="right-address">
            <span style={{ display: "flex", fontWeight: "600" }}>
              Email : &nbsp; <p> connect@brotocode.com</p>
            </span>

            <div className="social-icons">
              <p style={{ textAlign: "left", color: "#fff" }}>Social Links -</p>
              <a
                href="https://www.instagram.com/brotocode/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            @ Copyright 2022 Brotocode.com
          </p>
        </div>
      </div>
    </>
  );
};

export default Contact;
