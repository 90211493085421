import WebDesignSVG from "../../assets/images/GraphicDesignIcon.svg";
import WebDevSVG from "../../assets/images/web_development.svg";
import LogoBrandSVG from "../../assets/images/logo&branding.svg";
import ProductDesignSVG from "../../assets/images/product_design.svg";
import MarketingSVG from "../../assets/images/marketing.svg"
import IllustrationSVG from "../../assets/images/illus.svg";
import AppDevSVG from "../../assets/images/app_dev.svg";
 

const serviceBoxes = [
  {
    id: "5",
    logo: MarketingSVG,
    title: "Restaurant & Hotel Marketing",
    description:
      "Your ads will be featured on digital menus accessed via QR codes in top hotels and restaurants. These are places where patrons are ready to indulge, making them prime targets for your marketing efforts.",
    long_description: {
      introduction:
        "Welcome to BROTOCODE, your trusted partner in product design and development. We specialize in turning innovative ideas into tangible products that solve real-world problems and captivate your target market. Our team of skilled designers, engineers, and product strategists is committed to guiding you through every step of the product development journey, from concept to launch.",
      approach:
        "At BROTOCODE, we believe that successful products are born from a blend of creativity, user-centric design, and meticulous engineering. Our holistic approach encompasses the following key aspects:",
      services: [
        {
          "Conceptualization and Ideation":
            " We work with you to transform your vision into a well-defined product concept, outlining its core features and benefits.",
        },
        {
          "Product Design and Engineering":
            " Our team of designers and engineers collaborates to create detailed product designs, considering aesthetics, functionality, and manufacturability.",
        },
        {
          "Prototyping and Testing":
            "We develop functional prototypes to test and validate the product's design, functionality, and user experience.",
        },
        {
          "Manufacturing Support":
            "Our expertise extends to providing guidance during the manufacturing process, ensuring quality and efficiency in production.",
        },
        {
          "Product Launch and Market Entry":
            "We assist in launching your product to the market, helping you make a strong entrance and gain a competitive edge.",
        },
      ],
      process: [
        {
          "Discovery and Requirement Gathering":
            " We start by understanding your product vision, target market, and specific requirements.",
        },
        {
          "Ideation and Conceptualization":
            "Based on the gathered information, we brainstorm and create a product concept that aligns with your goals.",
        },
        {
          "Design and Development:":
            "Our team brings the concept to life through detailed design and engineering, creating functional prototypes for testing.",
        },
        {
          "Testing and Validation":
            "We conduct rigorous testing to ensure the product meets quality standards and user expectations..",
        },
        {
          "Manufacturing and Launch":
            "Once the product is ready for production, we guide you through the manufacturing process and support your product launch.",
        },
        {
          "Transform Your Vision into Reality with Expert Product Design and Development":
            "Ready to turn your ideas into a market-ready product? Contact us today for a free consultation, and let's embark on an exciting product development journey together.",
        },
      ],
    },
  },
  {
    id: "1",
    logo: WebDesignSVG,
    title: "WEB DESIGN",
    description:
      "We are passionate about crafting exceptional web experiences that leave a lasting impression. Whether you need a stunning website for your business or want to revamp your existing online presence, our team will do it..!",
    long_description: {
      introduction:
        "Welcome to our Web Design Services! At BROTOCODE, we are passionate about crafting exceptional web experiences that leave a lasting impression. Whether you need a stunning website for your business or want to revamp your existing online presence, our team of talented designers is here to bring your vision to life.",
      approach:
        "At BROTOCODE, we believe that a successful website is not just about aesthetics; it's about creating an intuitive and user-friendly platform that engages your audience and drives results. Our web design process focuses on understanding your business goals, target audience, and industry to deliver a website that stands out from the competition.",
      services: [
        {
          "Responsive Design":
            "We create websites that adapt seamlessly to all devices, ensuring your visitors have a consistent and enjoyable browsing experience, whether they're on desktops, laptops, tablets, or smartphones.",
        },
        {
          "Custom Design":
            "Every business is unique, and we tailor our web designs to reflect your brand identity, values, and personality. Your website will be one-of-a-kind and distinctively yours.",
        },
        {
          "Intuitive Navigation":
            "User-friendly navigation is at the core of our designs. We structure your website in a way that makes it easy for visitors to find the information they need, reducing bounce rates and improving user satisfaction.",
        },
        {
          "SEO-Friendly Design":
            "Our designs are search engine optimized, making it easier for your website to rank higher in search engine results, driving organic traffic to your business.",
        },
      ],
      process: [
        {
          "Discovery Phase":
            "We start by understanding your business, goals, and target audience. We conduct in-depth research to gain insights into your industry and competitors.",
        },
        {
          "Planning and Wireframing":
            "Based on the gathered information, we create a detailed plan and wireframe for your website, outlining the site's structure and user flow.",
        },
        {
          "Design and Development":
            "Our design team transforms the wireframes into stunning visual designs. Once approved, our developers bring those designs to life using the latest web technologies.",
        },
        {
          "Testing and Quality Assurance":
            "Before launch, we rigorously test your website across different devices and browsers to ensure its functionality and responsiveness.",
        },
        {
          "Launch and Maintenance":
            "Once your website is ready, we launch it for the world to see. We also offer ongoing maintenance and support to keep your website running smoothly.",
        },
        {
          "Get Started with Exceptional Web Design":
            "Ready to take your online presence to the next level? Contact us today for a free consultation and let us help you create a website that leaves a lasting impression on your audience.",
        },
      ],
    },
  },
  {
    id: "2",
    logo: WebDevSVG,
    title: "WEB DEVELOPMENT",
    description:
      "Our team of skilled developers is passionate about turning ideas into functional and dynamic websites that drive results. Whether you need a robust e-commerce platform or a custom web application, we have the expertise to bring your vision to life.",
    long_description: {
      introduction:
        "Welcome to BROTOCODE, your trusted partner for exceptional web development solutions. Our team of skilled developers is passionate about turning ideas into functional and dynamic websites that drive results. Whether you need a robust e-commerce platform, a captivating portfolio website, or a custom web application, we have the expertise to bring your vision to life.",
      approach:
        "At BROTOCODE, we follow a comprehensive and agile approach to web development. We combine creativity, technical expertise, and strategic thinking to create web solutions that align with your business objectives and exceed your expectations..",
      services: [
        {
          "Custom Web Solutions":
            "We understand that every business has unique requirements. Our custom web development services ensure that your website or web application is tailor-made to suit your specific needs.",
        },
        {
          "Scalability and Flexibility":
            "Our development approach ensures that your website is scalable and adaptable to accommodate future growth and changes in your business.",
        },
        {
          "User-Friendly Interfaces":
            "We focus on creating intuitive user interfaces (UI) that enhance user experience (UX) and keep visitors engaged with your website.",
        },
        {
          "Cross-Platform Compatibility":
            "Our developers ensure that your website works seamlessly across different devices and browsers, reaching a wider audience.",
        },
        {
          "Security and Performance":
            "We prioritize the security of your website and implement measures to protect it from potential threats. Additionally, we optimize performance to deliver fast-loading and responsive web experiences.",
        },
      ],
      process: [
        {
          "Discovery and Planning":
            "We begin by understanding your business goals, target audience, and project requirements. We collaborate closely with you to define the scope and objectives of the web development project.",
        },
        {
          "Design and Prototyping":
            "Our team creates wireframes and prototypes to visualize the website's layout and functionalities. We seek your feedback to ensure the design aligns with your vision.",
        },
        {
          "Development and Testing":
            "Our developers bring the approved design to life, adhering to best coding practices and industry standards. Rigorous testing is conducted to identify and resolve any bugs or issues.",
        },
        {
          "Launch and Deployment":
            "Once the website passes our stringent testing phase, we deploy it to a live environment and conduct final checks to ensure a smooth launch.",
        },
        {
          "Post-Launch Support":
            "We provide ongoing support and maintenance to keep your website running smoothly. Our team is always ready to assist with updates, enhancements, and technical support.",
        },
        {
          "Get Started with Innovative Web Development":
            "Ready to transform your online presence and enhance your digital capabilities? Contact us today for a free consultation and let us bring your web development dreams to reality.",
        },
      ],
    },
  },
  {
    id: "3",
    logo: LogoBrandSVG,
    title: "LOGO & BRANDING",
    description:
      "We understand the significance of a strong brand identity in today's competitive market. Our team of creative designers are dedicated to crafting visual representations that resonate with your target audience.",
    long_description: {
      introduction:
        "Welcome to BROTOCODE, your destination for exceptional logo and branding services. We understand the significance of a strong brand identity in today's competitive market. Our team of creative designers and branding experts is dedicated to crafting visual representations that resonate with your target audience and leave a lasting impression. Whether you're starting a new venture or looking to revamp your brand, we are here to bring your vision to life.",
      approach:
        "At BROTOCODE we believe that a successful brand identity is a culmination of creativity, strategy, and authenticity. We work closely with you to understand your brand's personality, values, and positioning in the market. Our approach is designed to create logos and branding elements that not only look visually stunning but also align with your business objectives.",
      services: [
        {
          "Custom Logo Design":
            " We create unique and custom logos that reflect the essence of your brand. Each logo is carefully crafted to represent your business's core values and vision.",
        },
        {
          "Brand Strategy":
            "We develop comprehensive brand strategies to ensure consistency across all touchpoints. From colors to typography to messaging, we ensure that your brand communicates a cohesive and compelling story.",
        },
        {
          "Brand Guidelines":
            "To maintain brand consistency, we provide detailed brand guidelines that serve as a reference for logo usage, color palettes, and brand tone.",
        },
        {
          "Brand Collateral":
            "We extend your brand identity to various marketing materials, including business cards, letterheads, brochures, and more.",
        },
        {
          "Digital Branding":
            "Our services include creating branded assets for digital platforms, such as social media graphics, email templates, and website elements.",
        },
        {
          "Rebranding and Brand Refresh":
            " If your brand needs a fresh start or a modern update, we specialize in rebranding and brand refresh services.",
        },
      ],
      process: [
        {
          "Discovery and Research":
            "We begin by understanding your business goals, target audience, and competition. We conduct in-depth research to identify opportunities for your brand.",
        },
        {
          "Concept Development":
            "Based on the insights gathered, we brainstorm and create multiple logo and branding concepts that align with your brand's personality.",
        },
        {
          "Design and Refinement":
            "We present the logo and branding concepts to you for feedback. With your input, we refine the designs to perfection.",
        },
        {
          "Elevate Your Brand with Exceptional Logo and Branding":
            "Ready to elevate your brand and make a lasting impact on your audience? Contact us today for a free consultation and let us create a brand identity that sets you apart from the competition.",
        },
      ],
    },
  },
  {
    id: "4",
    logo: ProductDesignSVG,
    title: "PRODUCT DESIGN",
    description:
      "Our team of skilled designers are committed to guiding you through every step of the product designing and development journey.",
    long_description: {
      introduction:
        "Welcome to BROTOCODE, your trusted partner in product design and development. We specialize in turning innovative ideas into tangible products that solve real-world problems and captivate your target market. Our team of skilled designers, engineers, and product strategists is committed to guiding you through every step of the product development journey, from concept to launch.",
      approach:
        "At BROTOCODE, we believe that successful products are born from a blend of creativity, user-centric design, and meticulous engineering. Our holistic approach encompasses the following key aspects:",
      services: [
        {
          "Conceptualization and Ideation":
            " We work with you to transform your vision into a well-defined product concept, outlining its core features and benefits.",
        },
        {
          "Product Design and Engineering":
            " Our team of designers and engineers collaborates to create detailed product designs, considering aesthetics, functionality, and manufacturability.",
        },
        {
          "Prototyping and Testing":
            "We develop functional prototypes to test and validate the product's design, functionality, and user experience.",
        },
        {
          "Manufacturing Support":
            "Our expertise extends to providing guidance during the manufacturing process, ensuring quality and efficiency in production.",
        },
        {
          "Product Launch and Market Entry":
            "We assist in launching your product to the market, helping you make a strong entrance and gain a competitive edge.",
        },
      ],
      process: [
        {
          "Discovery and Requirement Gathering":
            " We start by understanding your product vision, target market, and specific requirements.",
        },
        {
          "Ideation and Conceptualization":
            "Based on the gathered information, we brainstorm and create a product concept that aligns with your goals.",
        },
        {
          "Design and Development:":
            "Our team brings the concept to life through detailed design and engineering, creating functional prototypes for testing.",
        },
        {
          "Testing and Validation":
            "We conduct rigorous testing to ensure the product meets quality standards and user expectations..",
        },
        {
          "Manufacturing and Launch":
            "Once the product is ready for production, we guide you through the manufacturing process and support your product launch.",
        },
        {
          "Transform Your Vision into Reality with Expert Product Design and Development":
            "Ready to turn your ideas into a market-ready product? Contact us today for a free consultation, and let's embark on an exciting product development journey together.",
        },
      ],
    },
  },

  /*  {
    id: "5",
    logo: IllustrationSVG,
    title: "DIGITAL MARKETING",
    description:
      "Our dedicated team of marketing experts is committed to helping businesses.",
    long_description: {
      introduction:
        "Welcome to BROTOCODE, your partner in strategic marketing solutions. Our dedicated team of marketing experts is committed to helping businesses like yours reach new heights through creative and result-driven marketing. Whether you're a startup, small business, or established ent, we have the expertise and passion to elevate your brand and drive measurable success.",
      approach:
        "At BROTOCODE, we believe that effective marketing is a blend of art and science. Our approach encompasses a mix of creativity, data-driven strategies, and innovation to deliver compelling marketing campaigns that resonate with your target audience. We tailor our services to match your unique needs and goals, ensuring maximum impact and return on investment.",
      services: [
        {
          "Digital Marketing":
            "Our digital marketing solutions encompass a comprehensive range of services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, and content marketing. We leverage the power of the digital landscape to expand your online presence and drive traffic to your website.",
        },
        {
          "Branding and Identity":
            " We help you establish a distinctive brand identity that captures the essence of your business. From logo design to brand messaging, we ensure that your brand stands out in a crowded market.",
        },
        {
          "Content Creation":
            "Compelling content is the backbone of successful marketing. Our content creation services include engaging blog posts, captivating social media content, informative infographics, and persuasive copywriting that connects with your audience.",
        },
        {
          "Marketing Strategy":
            "Our team develops strategic marketing plans tailored to your business objectives. We analyze market trends, conduct competitor research, and identify growth opportunities to ensure your marketing efforts are targeted and impactful.",
        },
        {
          "Lead Generation and Conversion Optimization":
            " We focus on driving qualified leads to your business and optimizing conversion rates to maximize your sales and revenue.",
        },
      ],
      process: [
        {
          "Discovery and Analysis":
            " We start by understanding your business, industry, target audience, and current marketing efforts.",
        },
        {
          "Strategy Development":
            "Based on the gathered insights, we develop a customized marketing strategy aligned with your goals.",
        },
        {
          "Implementation and Execution":
            "Our team puts the strategy into action, executing marketing campaigns with precision and creativity.",
        },
        {
          "Data Analysis and Optimization":
            "We continuously monitor campaign performance, analyze data, and make data-driven adjustments for optimal results.",
        },
        {
          "Reporting and Communication":
            "We provide transparent and detailed reports, keeping you informed about the progress and impact of our marketing efforts.",
        },
        {
          "Unlock Your Business's Potential with Powerful Marketing Solutions":
            "Ready to take your marketing to the next level? Contact us today for a free consultation, and let's embark on a journey of growth and success together.",
        },
      ],
    },
  }, */
  /* {
    id: "6",
    logo: AppDevSVG,
    title: "APP DEVELOPMENT",
    description:
      "We work collaboratively with you throughout the process, ensuring that your app meets your vision and business objectives.",
    long_description: {
      introduction:
        " Welcome to BRROTOCODE, your go-to destination for cutting-edge app development solutions. Whether you're an ambitious startup or an established enterprise, we have the expertise to turn your app idea into reality and make a lasting impact in the digital world.",
      approach:
        "At BROTOCODE, we believe that successful app development goes beyond coding. Our approach combines creativity, functionality, and a user-centered mindset to create apps that engage, delight, and deliver real value. We work collaboratively with you throughout the process, ensuring that your app meets your vision and business objectives.",
      services: [
        {
          "iOS App Development":
            "Our team specializes in creating seamless and intuitive iOS applications that cater to the unique user experience of Apple device users.",
        },
        {
          "Android App Development":
            "We develop high-performance and feature-rich Android apps that leverage the vast user base of the Android platform.",
        },
        {
          "Cross-Platform Development":
            "Our expertise extends to cross-platform app development, allowing you to reach a broader audience while optimizing development time and costs.",
        },
        {
          "UI/UX Design":
            "We prioritize user interface (UI) and user experience (UX) design to ensure your app is visually appealing, easy to navigate, and offers an exceptional user journey.",
        },
        {
          "App Testing and Quality Assurance":
            "Rigorous testing and quality assurance are integral to our app development process, ensuring a smooth and bug-free user experience.",
        },
      ],
      process: [
        {
          "Discovery and Requirement Gathering":
            "  We begin by understanding your app concept, target audience, and specific requirements to develop a clear project scope.",
        },
        {
          "UI/UX Design and Prototyping":
            "Our designers create wireframes and prototypes to visualize the app's design and user flow.",
        },
        {
          "App Development and Coding":
            "Our experienced developers bring the app to life, utilizing the latest technologies and best coding practices..",
        },
        {
          "	Testing and Refinement":
            "We conduct comprehensive testing to identify and fix any issues, ensuring the app meets high standards of quality.",
        },
        {
          "App Launch and Deployment":
            "Once the app is ready, we assist with the app submission process and guide you through the launch.",
        },
        {
          "Post-Launch Support":
            " We provide ongoing support, updates, and maintenance to ensure your app's optimal performance and scalability.",
        },
        {
          "Turn Your App Vision into Reality with Expert App Development":
            "Ready to transform your app idea into a reality? Contact us today for a free consultation, and let's create an app that sets you apart in the competitive digital landscape.",
        },
      ],
    },
  }, */
];

export default serviceBoxes;
