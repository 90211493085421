import React from "react";
import { useNavigate } from "react-router-dom";
import "./Services.css";
import RightArrow from "../../assets/images/right-arrow.svg";
import useMediaQuery from "use-mediaquery";

import serviceBoxes from "../../assets/config/servicesConfig";

const Services = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  
  const navigate = useNavigate();

  const handleDetialsNavigate = (each) => {
    // Convert the each object to JSON string
    const eachDataJSON = JSON.stringify(each);
  /*   navigate({
      pathname: "/service-details",
      search: `service-detail=${encodeURIComponent(eachDataJSON)}`,
    }); */
  };

  return (
    <section className="main-section-container" id="services">
      <div className="top-service-headings">
        {isMobile ? (
          <h1> List Of Services We Provide</h1>
        ) : (
          <h1 style={{ textAlign: 'center'}}>
            List Of Services  We Provide
          </h1>
        )}

       
      </div>
      <div className="service-grid">
        {serviceBoxes &&
          serviceBoxes.map((each) => (
            <div
              className="main-services-boxes"
              key={each.id}
              onClick={() => handleDetialsNavigate(each)}
            >
              <div className="image-container-service">
                <img src={each.logo} alt="" />
              </div>
              <h2>{each.title}</h2>
              <p>{each.description}</p>
            </div>
          ))}
      </div>
    </section>
  );
};

export default Services;
