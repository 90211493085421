import React, { useState } from "react";
import { Link } from "react-scroll";
import "./Header.css";
import logoFont from "../../assets/font/InriaSans-Bold.ttf";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "use-mediaquery";
import { GrContact } from "react-icons/gr";
import emailSvg from "../../assets/images/mail.svg";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const navigate = useNavigate();

  const closeMenu = () => setClick(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleContactUsClick = () => {
    const emailAddress = "connect@brotocode.com"; // Replace with your email
    const subject = "Client Details"; // You can customize the email subject here

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  };
  return (
    <nav
      className="w-screen navbar   z-10  h-[80px]   top-0 fixed"
      
    >
      <div className="justify-between  mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center px-4  justify-between py-3 md:py-5 md:block">
            <Link
              to="home" 
              smooth={true}
              offset={-100}
              duration={500}
            >
              <h1
                style={{ fontFamily: logoFont, cursor: "pointer" }}
                className="text-4xl sm:text-2xl font-bold lg:mr-30 md:mr-10 sm:mr-0   "
              >
                Brotocode
              </h1>
            </Link>
            <div className="mobile-email-button">
              {isMobile ? (
                <>
                  <img
                    src={emailSvg}
                    alt=""
                    onClick={() => {handleContactUsClick()}}
                    style={{ cursor: "pointer" }}
                  />
                </>
              ) : (
                ""
              )}
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400  "
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 "
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6  "
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3  md:block md:pb-0 md:mt-0 ${
              navbar ? "block mobile_nav mt-3 px-0 pb-0 " : "hidden"
            }`}
          >
            <ul
              className={
                click
                  ? "items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 active"
                  : "items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0"
              }
            >
              <li>
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setNavbar(false)}
                >
                  Home
                </Link>
              </li>
              <li className=" hover:text-indigo-200">
                <Link
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setNavbar(false)}
                >
                  Services
                </Link>
              </li>
              <li
                className=" hover:text-indingo-200"
                onClick={() => navigate("/tech-stack")}
              >
                <span style={{ color: '#000', fontWeight: "600"}}> Tech Stack</span>
              </li>
              <li className=" hover:text-indingo-200">
                <Link
                  to="expertise"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setNavbar(false)}
                >
                  Expertise
                </Link>
              </li>
              {/* <li className="text-white hover:text-indigo-200">
                <Link
                  to="aboutUs"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setNavbar(false)}
                >
                  About US
                </Link>
              </li> */}

              <li className=" hover:text-indigo-200">
                <Link
                  to="contactUs"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onClick={() => setNavbar(false)}
                >
                  Contact US
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
