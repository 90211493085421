import React from "react";
import Banner from "../Banner/Banner";
import Header from "../Header/Header";
import Services from "../Services/Services"; 
import Expertise from "../Expertise/Expertise";
import Contact from "../ContactUs/Contact"; 

const Home = () => {
  const gradient = "#fff";
  return (
    <div style={{ background: "#fff", color: '#000 !important'}}>
      <Header />
      <Banner />
      <Services />
      <Expertise />
      <Contact />
    </div>
  );
};

export default Home;
