import React, { useEffect} from "react";
import "./TechStack.css";
import ReactLogo from "../../assets/images/react.svg";
import MongoDb from "../../assets/images/mongodb.svg";
import Js from "../../assets/images/js.svg";
import node from "../../assets/images/node.svg";
import tailwind from "../../assets/images/tailwind.svg";
import express from "../../assets/images/express.svg";
import html from "../../assets/images/html.svg";
import sass from "../../assets/images/sass.svg";
import xd from "../../assets/images/xd.svg";
import ai from "../../assets/images/ai.svg";
import figma from "../../assets/images/figma.svg";
import useMediaQuery from "use-mediaquery";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const TechStack = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const techStack = [
    {
      id: "1",
      name: "React",
      image: ReactLogo,
    },
    {
      id: "2",
      name: "MongoDB",
      image: MongoDb,
    },
    {
      id: "3",
      name: "JavaScript",
      image: Js,
    },
    {
      id: "4",
      name: "NodeJs",
      image: node,
    },
    {
      id: "5",
      name: "Tailwind",
      image: tailwind,
    },
    {
      id: "6",
      name: "React Native",
      image: ReactLogo,
    },
    {
      id: "7",
      name: "Express",
      image: express,
    },
    {
      id: "8",
      name: "Html",
      image: html,
    },
    {
      id: "9",
      name: "Sass",
      image: sass,
    },
    {
      id: "10",
      name: "AdobeXD",
      image: xd,
    },
    {
      id: "11",
      name: "Adobe Ai",
      image: ai,
    },
    {
      id: "12",
      name: "Figma",
      image: figma,
    },
  ];
  const gradient = "linear-gradient(#360033 -28.1%, #0BB798 159.99%)";
  const navigate = useNavigate();
   useEffect(() => {
    window.scrollTo(0, 800);
     // eslint-disable-next-line
   }, []);
  return (
    <div
      id="techStack"
      className="main-tech-container"
      style={{ background: "#f6edfa" }}
    >
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <div className="back-div" onClick={() => navigate("/")}>
          <BiArrowBack color="#000" size={30} />
        </div>
        <p>Back</p>
      </div>

      <h1
        style={{
          textAlign: "center",
          color: "#000",
          fontFamily: "Piazzolla",
          margin: "20px 0px",
          fontSize: isMobile ? "32px" : "",
        }}
      >
        Technologies We Use
      </h1>
      <div className="tech-boxes-container">
        {techStack &&
          techStack.map((e) => (
            <div className="tech-boxes" key={e.id}>
              <img src={e.image} alt="" />
              <h3>{e.name}</h3>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TechStack;
